:root {
  --white: #fff;
  --black: #000;
  --tertiary-black: #0f0617;

  --purple-100: #bdabf9;
  --purple-200: #8230c6;
  --purple-300: #260e3a;
  --purple-400: #2a2135;
  --purple-500: #1d1622;

  --purple-translucent: hsla(273, 60%, 6%, 0.9);
  --purple-transparent: hsla(272, 59%, 6%, 0);

  --purple-gradient: rgba(130, 48, 198, 0.2) 0.14%,
    rgba(130, 48, 198, 0) 140.96%;

  --gray-100: #f4f4f4;
  --gray-200: #ddd;
  --gray-300: #bdbdbd;
  --gray-400: #808080;
  --gray-500: #737373;
  --gray-600: #5d5b5d;
  --gray-700: #4f4f4f;
  --gray-800: #333;

  --green-success: #16a160;
  --red-failure: #d13821;
}
