@import 'primeflex/primeflex.scss';
@import './assets/variables.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Noto+Sans:wght@400;700&display=swap');
@import 'ngx-toastr/toastr';

html,
body {
  margin: 0;
  font-size: 16px;
  &,
  * {
    font-family: 'Noto Sans', sans-serif !important;
  }
  background-color: var(--tertiary-black);
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.purple-200 {
  color: var(--purple-200);
}

.gray-100 {
  color: var(--gray-100);
}
.gray-200 {
  color: var(--gray-200);
}
.gray-300 {
  color: var(--gray-300);
}
.gray-400 {
  color: var(--gray-400);
}

.gray-500 {
  color: var(--gray-500);
}

.gray-600 {
  color: var(--gray-600);
}

.gray-700 {
  color: var(--gray-700);
}

.green-success {
  color: var(--green-success);
}

.red-failure {
  color: var(--red-failure);
}

.white {
  color: var(--white);
}

.btn {
  border: 0;
}

.primary-btn {
  background-color: var(--purple-200);
  padding: 1.05rem;
  margin: 20px;
  width: calc(100% - 40px);
  color: var(--white);
}
.proceed-btn-container {
  @media (min-width: 768px) {
    padding: 20px;
    margin: 0 -20px 4rem;
    background: var(--purple-400);

    .primary-btn {
      margin: 0;
      width: 100%;
    }
  }
}

.disabled {
  background-color: var(--gray-200);
  color: var(--gray-400);
}

.no-background {
  background: none;
}

.line-through {
  text-decoration-color: var(--red-failure) !important;
}

.float-left {
  float: left;
}

.text-xxs {
  font-size: 0.625rem;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

.option-container {
  padding: 1.25rem;
  margin-bottom: 0.65rem;
  .info-icon {
    fill: var(--gray-200);
  }
}

.selected {
  border: 1px solid var(--purple-200);
  background-color: var(--purple-300);
}
.default {
  border: 1px solid var(--gray-500);
  background: none;
}
/* For modern browsers */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* If you need to remove the default margin */
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
